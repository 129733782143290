<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/note/note' }"
          >笔记列表</el-breadcrumb-item
        >
        <el-breadcrumb-item>添加</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!--内容-->
    <div class="page-content-x">
      <el-row>
        <el-col :span="17">
          <el-form size="medium" ref="form" :label-width="this.env.label_width">
            <el-col :span="24">
              <el-form-item label="选择用户">
                <el-select
                  v-model="form.user_uuid"
                  filterable
                  placeholder="请选择用户"
                  remote
                  :remote-method="getuser"
                  :loading="select_loading"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(item, key) in user_list"
                    :key="key"
                    :label="item.user_nick"
                    :value="item.user_uuid"
                  >
                    <span>{{ item.user_nick }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="上传笔记图片">
                <Qnupload v-model="form.photo" :sum="9" :fixedNumber="[1, 1]" />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="笔记视频">
                <Qnupload v-model="form.video" type="video" @input="getVideo" />
                <span class="form-tip form-tip-red"
                  >如果上传视频，图片将不生效</span
                >
              </el-form-item>
            </el-col>
            <el-col v-if="form.video" :span="24">
              <el-form-item label="视频封面">
                <Qnupload
                  v-model="form.video_cover"
                  :sum="1"
                  :fixedNumber="[1, 1]"
                />
                <el-button
                  v-if="!form.video_cover"
                  type="primary"
                  @click="getVideoCover"
                  >使用视频第一帧图片
                </el-button>
                <p style="margin: 0; color: #fc142f">
                  若未上传视频封面，则会截取视频第一帧画面
                </p>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="输入笔记标题">
                <el-input v-model="form.title"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="笔记内容">
                <el-input
                  v-model="form.content"
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 6 }"
                  maxlength="1000"
                  show-word-limit
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="添加话题">
                <div>
                  <el-select
                    :disabled="topic_disabled"
                    @change="add_topic"
                    v-model="topic_tag"
                    filterable
                    placeholder="请选择话题"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="(item, key) in topic_list"
                      :key="key"
                      :label="item.topic"
                      :value="
                        item.topic + '|' + item.colour + '|' + item.topic_uuid
                      "
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="form-tag_colour-div">
                  <el-tag
                    v-for="(item, key) in this.form.topic_uuid"
                    :key="key"
                    class="form-tag_colour-tag"
                    effect="dark"
                    closable
                    size="small"
                    @close="tagClose(item, 'topic_uuid')"
                    :color="item.colour"
                  >
                    {{ item.topic }}
                  </el-tag>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="关联门店">
                <el-select
                  v-model="form.store_uuid"
                  filterable
                  placeholder="请选择门店"
                  remote
                  :remote-method="get_store_list"
                  :loading="select_loading"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(item, key) in store_list"
                    :key="key"
                    :label="item.store_name"
                    :value="item.store_uuid"
                  >
                    <span>{{ item.store_name }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="运动品类">
                <MotionCategorySelect
                  :max="1"
                  :motionCategoryList.sync="motion_category_two"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item>
                <el-button
                  v-if="is_auth('note.fictitious.addnote')"
                  size="medium"
                  type="primary"
                  @click="save"
                  >保存
                </el-button>
                <el-button size="medium" @click="isreturn">返回</el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import Qnupload from "@/components/Qnupload";
import MotionCategorySelect from "@/components/MotionCategorySelect";

export default {
  components: {
    Qnupload,
    MotionCategorySelect,
  },
  data() {
    return {
      note_uuid: "",
      select_loading: false,
      store_list: [], // 门店列表
      topic_disabled: false,
      topic_tag: "", // 话题标签
      topic_list: [], //话题列表
      user_list: [], //用户列表
      form: {
        photo: [],
        video: "",
        user_uuid: "", //用户信息
        store_uuid: [], // 可用门店信息
        topic_uuid: [], //可用话题
        video_cover: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      motion_category_two: [],
    };
  },
  // 创建
  async created() {
    let note_uuid = this.$route.query.note_uuid;
    await this.gettopic(); //回去话题
    if (note_uuid) {
      this.note_uuid = note_uuid;
      this.getInfo();
    }
    this.get_store_list(null); // 获取可选门店
    this.getuser(null); // 获取用户
  },
  // 安装
  mounted() {},
  methods: {
    getInfo() {
      const { note_uuid } = this;
      let postdata = {
        api_name: "note.note.details",
        token: this.Tool.get_l_cache("token"),
        note_uuid,
      };
      this.loading = true;
      this.Tool.post_data("oss", postdata, (res) => {
        if (res.code === 0) {
          const { topic_list } = this;
          let topic_uuid = [];
          for (let i in topic_list) {
            for (let j in res.data.topic_uuid) {
              if (topic_list[i].topic_uuid === res.data.topic_uuid[j]) {
                topic_uuid.push({
                  colour: topic_list[i].colour,
                  topic: topic_list[i].topic,
                  topic_uuid: topic_list[i].topic_uuid,
                });
              }
            }
          }
          this.motion_category_two = res.data.motion_category_two;
          this.form = {
            ...res.data,
            topic_uuid,
            store_uuid: res.data.store_uuid[0],
          };
        } else if (res.code === 10005) {
          this.Tool.errormes(res);
        }
        this.loading = false;
      });
    },
    // 获取笔记详情的话题列表
    returnNoteTopic() {},
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 获取用户
    getuser(key) {
      let postdata = {
        api_name: "user.fictitious.getuser",
        token: this.Tool.get_l_cache("token"),
        user_nick: key,
      };
      this.select_loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.select_loading = false;
        console.log("json.data.", json.data);
        if (json.code === 0) {
          this.user_list = json.data.list;
        } else {
          // this.store_list = []
        }
      });
    },
    // 获取门店列表
    get_store_list(key) {
      let postdata = {
        api_name: "note.fictitious.getstore",
        token: this.Tool.get_l_cache("token"),
        store_name: key,
      };
      this.select_loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.select_loading = false;
        if (json.code === 0) {
          this.store_list = json.data.list;
        } else {
          // this.store_list = []
        }
      });
    },
    // 获取话题
    gettopic() {
      let postdata = {
        api_name: "note.fictitious.gettopic",
        token: this.Tool.get_l_cache("token"),
      };
      this.select_loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.select_loading = false;
        if (json.code === 0) {
          this.topic_list = json.data.list;
        } else {
          // this.store_list = []
        }
      });
    },
    add_topic() {
      let select_tag = this.topic_tag.split("|");

      let tag = {
        topic: select_tag[0],
        colour: select_tag[1],
        topic_uuid: select_tag[2],
      };
      if (!this.tag_exist(tag.topic, "topic_uuid")) {
        this.form.topic_uuid.push(tag);
      }
      if (this.form.topic_uuid.length >= 10) {
        this.topic_disabled = true;
      }
      this.topic_tag = "";
      console.log(this.form.topic_uuid);
    },
    // 判断标签是否存在
    tag_exist(topic, key) {
      if (this.form[key].length > 0) {
        for (let v of this.form[key]) {
          if (topic == v.topic) {
            return true;
          }
        }
        return false;
      } else {
        return false;
      }
    },
    // 删除标签
    tagClose(tag, key) {
      this.form[key].splice(this.form[key].indexOf(tag), 1);
      this.topic_disabled = false;
    },
    save() {
      let postdata = {
        api_name: "note.fictitious.addnote",
        token: this.Tool.get_l_cache("token"),
      };
      // 非必须参数（搜索条件）

      Object.assign(postdata, this.form);
      let topic_uuid = [];
      for (let x of postdata.topic_uuid) {
        topic_uuid.push(x.topic_uuid);
      }
      postdata.motion_category_two = Number(
        this.motion_category_two.map((item) => item.id).join(",")
      );
      postdata.store_uuid = [postdata.store_uuid];
      postdata.topic_uuid = topic_uuid;
      this.loading = true;
      this.Tool.post_data("oss", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.page = 1;
              this.form = {};
              this.$router.push({ path: "/note/note" });
            },
          });
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    // 返回
    isreturn() {
      // this.$router.push({path: '/store/store'})
      this.$router.go(-1);
    },
    // 返回视频地址，并默认设置视频封面为视频第一帧画面
    getVideo(e) {
      this.form.video_cover = `${e}?vframe/jpg/offset/1`;
    },
    // 使用视频第一帧当视频封面
    getVideoCover() {
      this.form.video_cover = `${this.form.video}?vframe/jpg/offset/1`;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
